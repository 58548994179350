import ApplicationController from "../application_controller";

let debug = false

function clog(it) {
  if (debug) {
    console.log(it);
  }
}

export default class extends ApplicationController {
  static values = {
    afterSuccessLogin: String,
    showModal: String
  }

  connect() {
    this.setup();
  }

  disconnect() {
  }

  setup() {
    clog('Controller SETUP');
    if (this.afterSuccessLoginValue=="true" && this.showModalValue=="true") {
      this.openInvoiceModal();
    }
  }

  openInvoiceModal() {
    setTimeout(() => {
      Turbo.visit("/partner_area/settings/invoices/show_invoice_modal", { frame: "turbo_modal" })
    }, 500)
  }
}
