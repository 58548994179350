import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["downloadLink"]

    initialize() {
        this.registerEvents()
    }

    connect() {
    }

    disconnect() {
    }

    registerEvents(){
        this.downloadLinkTarget.addEventListener('ajax:success', this.ajaxSuccessHandler.bind(this));
    }

    unregisterEvents() {
        var r = this.downloadLinkTarget.removeEventListener('ajax:success', this.ajaxSuccessHandler.bind(this));
    }

    ajaxSuccessHandler(e) {
        if (e.detail[2].status == 200 && e.detail[2].getResponseHeader('Content-Disposition')){
            this.downloadBlob(e);
        }
    }

    getFilename(e) {
        var filename = "";
        var disposition = e.detail[2].getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                return filename = matches[1].replace(/['"]/g, '');
            }
        }
        return 'download.pdf'
    }

    downloadBlob(e, type) {
        var pdfW = window.open("");
        if(!pdfW || pdfW.closed || typeof pdfW.closed=='undefined') {
            alert('Você precisa habilitar as popups do sistema para utilizar esta funcionalidade.');
            return false;
        }

        setTimeout(function(){
            if(pdfW){
                var data = encodeURI(e.detail[0])
                pdfW.document.write("<a href='data:application/pdf;base64,"+data
                    + "' download='" + this.getFilename(e) + "' style='box-shadow:inset 0px 1px 0px 0px #cf866c; background:linear-gradient(to bottom, #d0451b 5%, #bc3315 100%); background-color:#d0451b; border-radius:3px; border:1px solid #942911; display:inline-block; cursor:pointer; color:#ffffff; font-family:Arial; font-size:13px; padding:6px 24px; text-decoration:none; text-shadow:0px 1px 0px #854629;'>"
                    + 'Download PDF' + "</a><br>");

                if( type == 'object') {
                    pdfW.document.write(
                        "<object width='100%' height='100%' type='application/pdf' data='data:application/pdf;base64, " + data + "'></object>"
                    );
                } else {
                    pdfW.document.write(
                        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + data + "'></iframe>");
                }

                pdfW.document.title = "Download PDF";
            }

        }.bind(this), 300);
    }

}
