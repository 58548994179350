import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import { put } from "@rails/request.js";


export default class extends CheckboxSelectAll {
    static values = {
        currentStatus: String
    }

    static targets = [
        "bulkActionsMenu",
        "checkedCounter",
        "failedDropdownAction",
        "doneDropdownAction",
        "startedDropdownAction",
        "assignDriverDropdownAction",
        "arriveDropdownAction"
    ]

    connect() {
        super.connect()
        // Get all checked checkboxes
        this.checked
        // Get all unchecked checkboxes
        this.unchecked
    }

    handleSingleSelect(event) {
        if(this.checked.length > 0) {
            this.showMenu()
            this.showButtonMenuAction(this.checked)
        } else {
            this.hideMenu()
        }
        this.updateCheckedCount();
    }

    handleSelectAll(event) {
        if(event.target.checked) {
            let self = this;
            self.updateCheckedCount(this.checked.length+this.unchecked.length);
            self.showMenu();
            self.showButtonMenuAction(this.checkboxTargets);
        } else {
            this.updateCheckedCount();
            this.hideMenu()
        }
    }

    done(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doTurboActionRequest('update_as_done', this.checked, this.getRedirectUrlParam(event), this.getTurboFrameParam(event));
        }
    }

    failure(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doTurboActionRequest('update_as_failure', this.checked, this.getRedirectUrlParam(event), this.getTurboFrameParam(event));
        }
    }

    start(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0 ) {
            this.doTurboActionRequest('update_as_start', this.checked, this.getRedirectUrlParam(event), this.getTurboFrameParam(event));
        }
    }

    arrive(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doTurboActionRequest("update_as_arrive", this.checked, this.getRedirectUrlParam(event), this.getTurboFrameParam(event));
        }
    }

    showModalBulkPerformAt(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doTurboModalRequest("bulk_perform_at", this.checked, this.getRedirectUrlParam(event), this.getTurboFrameParam(event));
        }
    }

    showModalBulkAssignDriver(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doTurboModalRequest("bulk_assign_driver", this.checked, this.getRedirectUrlParam(event), this.getTurboFrameParam(event), this.getShowMessageParam(event));
        }
    }

    showModalBulkUpdate(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doTurboModalRequest("show_bulk_update", this.checked, this.getRedirectUrlParam(event), this.getTurboFrameParam(event));
        }
    }

    updateCheckedCount(count) {
        this.checkedCounterTarget.innerHTML = count || this.checked.length;
    }

    showMenu() {
        this.bulkActionsMenuTarget.classList.remove("d-none");
    }

    hideMenu() {
        this.bulkActionsMenuTarget.classList.add("d-none");
    }

    doTurboActionRequest(action, checkboxes, redirectUrl, turboFrame) {
        let data = new FormData();
        checkboxes.forEach((checkbox) => data.append("bulk_ids[]", checkbox.value));

        data.append("redirect_url", redirectUrl);
        data.append("turbo_frame", turboFrame);

        put('demand_plannings/bulk/' + action, {
            body: data,
            responseKind: "turbo-stream"
        })
    }

    doTurboModalRequest(action, checkboxes, redirectUrl, turboFrame, showMessage = true) {
        let data = new FormData();
        checkboxes.forEach((checkbox) => data.append("bulk_ids[]", checkbox.value));

        data.append("redirect_url", redirectUrl);
        data.append("show_info_message", showMessage);
        data.append("turbo_frame", turboFrame);
        data.append("current_status", this.currentStatusValue);
        let params = new URLSearchParams(data).toString();

        Turbo.visit('demand_plannings/bulk/' + action + '?' + params, { frame: 'turbo_modal' });
    }

    getRedirectUrlParam(event) {
        return event.currentTarget.dataset['redirectUrlParam'];
    }

    getConfirmMsgParam(event) {
        return event.currentTarget.dataset['confirmMsgParam'];
    }

    getShowMessageParam(event) {
        return event.currentTarget.dataset['showMessageParam'];
    }

    getTurboFrameParam(event) {
        return event.currentTarget.dataset['turboFrameParam'];
    }

    // Desabilitar Ação
    showButtonMenuAction(checkboxes) {
        let hasFutureOrderJob = false;
        let orderItemStatus = false;
        let showActionsStatus = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            showActionsStatus = button.dataset['showActionsStatus'];
            if (!breakForEach) {
                hasFutureOrderJob = button.dataset['futurePerformAt']==='true';
                orderItemStatus = button.dataset['orderItemStatus']?.toString();
                if(hasFutureOrderJob) breakForEach = true;
            }
        });

        if (showActionsStatus==='true'){
            if(hasFutureOrderJob){
                //desabilitar
                //Atendido
                this.disableDropdownAction(this.doneDropdownActionTarget)
                //Em rota
                this.disableDropdownAction(this.startedDropdownActionTarget)
                //Falhou
                this.disableDropdownAction(this.failedDropdownActionTarget);
            } else {
                //Em rota
                this.showActionStarted(checkboxes)
                //Atendido
                this.showActionDone(checkboxes)
                //Falhou
                this.showActionFailed(checkboxes)
            }
        }

        if (showActionsStatus==='true'){
            // Chegou
            this.showActionArrive(checkboxes)
        }
    }

    // Em rota
    showActionStarted(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['showStarted']==="false";
                if(notShow) breakForEach = true;
            }
        });

        if(notShow){
            //desabilitar
            this.disableDropdownAction(this.startedDropdownActionTarget)
        } else {
            this.enableDropdownAction(this.startedDropdownActionTarget);
        }
    }

    // Atendido
    showActionDone(checkboxes) {
        let notShowDone = false;
        let breakForEachDone = false;

        (checkboxes).forEach((button) => {
            if (!breakForEachDone) {
                notShowDone = button.dataset['showDone']==="false";
                if(notShowDone) breakForEachDone = true;
            }
        });

        let notShowDoneForPlaced = false;
        let breakForEachDoneForPlaced = false;

        (checkboxes).forEach((button) => {
            if (!breakForEachDoneForPlaced) {
                notShowDoneForPlaced = button.dataset['showDoneForPlaced']==="false";
                if(notShowDoneForPlaced) breakForEachDoneForPlaced = true;
            }
        })

        if (!notShowDone) {
            this.enableDropdownAction(this.doneDropdownActionTarget);
            this.currentStatusValue = null
        } else if (!notShowDoneForPlaced) {
            this.enableDropdownAction(this.doneDropdownActionTarget);
            this.currentStatusValue = 'placed'
        } else {
            //desabilitar
            this.disableDropdownAction(this.doneDropdownActionTarget);
        }
    }

    // Falhou
    showActionFailed(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['showFailed']==="false";
                if(notShow) breakForEach = true;
            }
        });

        if (notShow) {
            //desabilitar
            this.disableDropdownAction(this.failedDropdownActionTarget)
        } else {
            this.enableDropdownAction(this.failedDropdownActionTarget);
        }
    }

    // Chegou
    showActionArrive(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['showArrive']==="false";
                if(notShow) breakForEach = true;
            }
        });

        if (notShow) {
            //desabilitar
            this.disableDropdownAction(this.arriveDropdownActionTarget)
        } else {
            this.enableDropdownAction(this.arriveDropdownActionTarget);
        }
    }

    disableDropdownAction(target) {
        let enabled = target?.getElementsByClassName("enabled-option");
        let disabled = target?.getElementsByClassName("disabled-option");

        enabled[0]?.classList.add("d-none");
        disabled[0]?.classList.remove("d-none");
    }

    enableDropdownAction(target) {
        let enabled = target?.getElementsByClassName("enabled-option");
        let disabled = target?.getElementsByClassName("disabled-option");

        enabled[0]?.classList.remove("d-none");
        disabled[0]?.classList.add("d-none");
    }
}