// reconnect_stimulus_after_sortable_controller.js

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Função para inicializar alguns controllers Stimulus
function initializeStimulus() {
    const application = Application.start()
    const context = require.context("controllers", true, /_controller\.js$/)
    const controllers = definitionsFromContext(context);

    // Identificando os controladores relevantes
    const controllerNames = [
        "partner-area--sortable-demand-plannings",
        // "partner-area--demand-plannings--others-order-jobs-bulk",
        // "partner-area--demand-plannings--driver-order-jobs-bulk"
    ];

    // Carregar cada controller
    controllerNames.forEach(controllerName => {
        const controller = controllers.find(def => def.identifier === controllerName);
        if (controller) {
            application.load(controller);
        } else {
            console.error(`Controller ${controllerName} não encontrado.`);
        }
    });
}

document.addEventListener("sortable-demand-plannings:update", function(event) {
    initializeStimulus();
});
