import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "menu", "toggle" ]

    connect() {
        if (!$(this.toggleTarget).hasClass('ignore-toggle')) {
            $(this.toggleTarget).trigger('click'); //force click to setup bootstrap dropdown
        }
    }

    disconnect() {
        $(this.toggleTarget).addClass('ignore-toggle')
    }
}