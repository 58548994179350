import ApplicationController from "./application_controller";
import _ from "lodash";
export default class extends ApplicationController {

    static values = {
        context: String,
        fetchUrl: String,
        dropdownParentId: String,
        pageSize: Number
    }
    connect() {
        this.init();
    }

    disconnect() {
    }

    init() {
        let dropdownParent = this.dropdownParentIdValue ? $("#"+this.dropdownParentIdValue) : $(document.body);
        let _this = this;
        $(this.element).select2({
            tags: true,
            tokenSeparators: [','],
            multiple: true,
            selectOnClose: false,
            language: "pt-BR",
            theme: "material",
            dropdownParent: dropdownParent,
            ajax: {
                url: this.fetchUrlValue+".json?page=1&items="+(this.pageSizeValue||10),
                dataType: 'json',
                delay: 500, //500ms wait after a user has stopped typing before sending the request
                data: function (params) {
                    return {
                        q: params.term || undefined,
                        page: params.page || 1,
                        ac: true,
                        not_in_names: _this.getCurrentSelected(),
                        context: _this.contextValue
                    };
                },
                processResults: function (data) {
                    return {
                        results: data.items.map(function(item) {
                            return {
                                id: item.name,
                                text: item.name,
                                key: item.name,
                                found: true
                            }
                        }),
                        pagination: {
                            more: !!data.metadata.next
                        }
                    };
                }
            },
            templateResult: this.templateResultTags.bind(this)
        }).on('select2:unselecting', function(event) {
                let originalEventType = event.params.args.originalEvent.type;
                if (this.getCurrentSelected().indexOf(event.params.args.data.text) > -1) {
                    if (originalEventType=='mouseup') {
                        event.preventDefault();
                        this.element.focus()
                    } //nao remove seja selecionado
                }
            }.bind(this)
        ).bind(this);
    }

    templateResultTags(tag, element) {
        let item = '';
        if (tag.loading){
            return $("<div class='d-flex flex-column flex-fill m-0'>" +
                "<div class='progress bg-light px-1 m-0 rounded' title=''>" +
                "<div class='progress-bar bg-info progress-bar-striped progress-bar-animated p-0' " +
                " style='width:0%' aria-valuemax='100' aria-valuemin='0' aria-valuenow='0' role='progressbar'>" +
                "<span class='font-weight-bold text-info small px-1'>" +
                tag.text + " (Aguarde)" +
                "<span>" +
                "</div></div></div>");

        }

        if(tag.found){
            item = "<span class='badge badge-pill badge-light small'>" + tag.text + "</span>";
        } else if(!_.includes(this.getCurrentSelected(), tag.text)){
            item = "<span class='d-block'> <strong>+ </strong><span class='font-weight-bold'>" + tag.text + "</span>";
            item += "<span class='ml-2 small font-weight-regular'>Clique aqui para incluir o texto digitado como um novo item</span>"
            item += "</span>";
        } else {
            item = "<span class='d-block'><span class='badge badge-pill badge-warning small'>" + tag.text;
            item += "</span> já está selecionado <span class='ml-2 small font-weight-regular'>Tecle <span class='text-monospace'>ESC</span> para limpar ou continue pesquisando</span>"
            item += "</span>";
        }

        return $(item)
    }

    getCurrentSelected() {
        let sel1 = $(this.element).select2('data').map(function(it){ return it.key });
        let sel2 = $(this.element).val();
        return _.union(sel1, sel2);
    }
}
