import ApplicationController from "../application_controller";
import StimulusReflex from "stimulus_reflex";
import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

var debug = false;

function clog(it) {
    if (debug) {
        console.log(it);
    }
}
export default class extends ApplicationController {

    static values = {
        partnerId: String,
    }

    connect() {
        this.element[this.identifier] = this;
        this.subscriptions = [];
        this.partnerId = this.partnerIdValue;
        StimulusReflex.register(this);
        debugger
        this.setup();
    }

    disconnect() {
        this.unregisterChannels();
    }

    setup() {
        clog('Controller SETUP');
        this.registerChannels();
    }

    registerChannels() {
        clog("creating subscription for PartnerChannel " + this.partnerId);
        this.subscriptions.push(
            //partner
            consumer.subscriptions.create(
                { channel: "PartnerChannel", partner_id: this.partnerId },
                {
                    received(data) {
                        clog("Received on channel ");
                        clog(data);
                        if (data.cableReady) CableReady.perform(data.operations);
                    },
                }
            )
        );
    }


    unregisterChannels() {
        for (var sub in this.subscriptions) {
            clog("removing subscriptions ");
            clog(sub);
            consumer.subscriptions.remove(sub);
        }
    }
}
