import CheckboxSelectAll from 'stimulus-checkbox-select-all'

export default class extends CheckboxSelectAll {
    static targets = ["bulkActionsMenu", "checkedCounter"]

    connect() {
        super.connect()
        // Get all checked checkboxes
        this.checked
        // Get all unchecked checkboxes
        this.unchecked
    }

    handleSingleSelect(event) {
        if(this.checked.length > 0) {
            this.showMenu()
        } else {
            this.hideMenu()
        }
        this.updateCheckedCount();
    }

    handleSelectAll(event) {
        if(event.target.checked) {
            let self = this;
            self.updateCheckedCount(this.checked.length+this.unchecked.length);
            if (this.checked.length+this.unchecked.length > 0) {
                self.showMenu();
            }
        } else {
            this.updateCheckedCount();
            this.hideMenu()
        }
    }

    updateCheckedCount(count) {
        this.checkedCounterTarget.innerHTML = count || this.checked.length
    }

    showMenu() {
        $(this.bulkActionsMenuTarget).attr('disabled', false);
    }

    hideMenu() {
        this.checkedCounterTarget.innerHTML = 0;
        $(this.bulkActionsMenuTarget).attr('disabled', true);
    }
}