import ApplicationController from "../application_controller";

export default class extends ApplicationController {
    static targets = [ "modal" ]
    static values = {
        backdrop: String,
        closeAfterSubmit: Boolean
    }

    connect() {
        this.open();
    }

    disconnect() {
        this.close();
    }

    open() {
        $(this.modalTarget).modal({
            show: true,
            backdrop: this.backdropValue
        });

        if (this.closeAfterSubmitValue) {
            document.addEventListener("turbo:submit-end", () => {
                setTimeout(() => {
                    this.close();
                }, 100)
            }, { once: true });
        }
    }

    close() {
        $(this.modalTarget).modal('hide');
    }
}
