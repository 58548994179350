import ApplicationController from "../application_controller";

let debug = false

function clog(it) {
  if (debug) {
    console.log(it);
  }
}

export default class extends ApplicationController {
  static targets = [ "metadata" ]
  connect() {
    this.element[this.identifier] = this;
  }

  disconnect() {
    // this.unregisterOrderJobsChanged();
    this.unregisterOrderJobsChangedMutation();
  }

  setup(map, mapCenter) {
    // called when map is ready
    clog('Controller SETUP');
    this.setupOrderJobsOnMap(map, mapCenter);
    // this.registerOrderJobsChanged(map, mapCenter);
    this.registerOrderJobsChangedMutation(map, mapCenter);
  }

  //First Setup
  setupOrderJobsOnMap(map, mapCenter) {
    var rowsDriver = $("#list_driver_order_jobs").find("tr");
    var rowsOthers = $("#list_others_order_jobs").find("tr");
    var rows = $.merge(rowsDriver, rowsOthers);
    var sortableRows = rows.filter('.sortable');

    this.addOrderJobsMapSource(map, sortableRows);
    this.addOrderJobClusterLayer(map);
    this.addOrderJobClusterCountLayer(map);
    this.addUnclusteredPointsLayer(map);
    this.addUnclusteredPointsLayerShortTitle(map);
    this.setupSpiderifierOnMap(map, mapCenter);
  }

  /**
   * @deprecated https://chromestatus.com/feature/5083947249172480
   * */
  registerOrderJobsChanged(mapObject, mapCenter) {
    var _self = this;

    $("body").on("DOMSubtreeModified", "#card_others_order_jobs", function (event) {
      _self.orderJobsChanged(mapObject, mapCenter);
    });

    $("body").on("DOMSubtreeModified", "#card_driver_order_jobs", function (event) {
      _self.orderJobsChanged(mapObject, mapCenter);
    });
  }

  registerOrderJobsChangedMutation(mapObject, mapCenter) {
    var _self = this;
    const cardOthersOrderJobs = document.getElementById("card_others_order_jobs");
    const cardDriverOrderJobs = document.getElementById("card_driver_order_jobs");

    const config = { attributes: false, childList: true, subtree: true };

    const mutationCallback = function(mutationList, observer){
      clog("order_jobs list changed");
      var rowsDriver = $("#list_driver_order_jobs").find("tr");
      var rowsOthers = $("#list_others_order_jobs").find("tr");
      var rows = $.merge(rowsDriver, rowsOthers);
      var sortableRows = rows.filter('.sortable');

      clog('OJ Number of rows: '+ sortableRows.length);
      _self.addOrderJobsMapSource(mapObject, sortableRows);
    };

    const othersOrderJobsObserver = new MutationObserver(mutationCallback);
    const driverOrderJobsObserver = new MutationObserver(mutationCallback);

    othersOrderJobsObserver.observe(cardOthersOrderJobs, config);
    driverOrderJobsObserver.observe(cardDriverOrderJobs, config);

    this.cardDriverOrderJobsObserver = driverOrderJobsObserver;
    this.cardOthersOrderJobsObserver = othersOrderJobsObserver;
  }

  /**
   * @deprecated https://chromestatus.com/feature/5083947249172480
   * */
  orderJobsChanged(mapObject, mapCenter) {
    var _self = this;

    clog("order_jobs list changed");
    var rowsDriver = $("#list_driver_order_jobs").find("tr");
    var rowsOthers = $("#list_others_order_jobs").find("tr");
    var rows = $.merge(rowsDriver, rowsOthers);
    var sortableRows = rows.filter('.sortable');

    clog('OJ Number of rows: '+ sortableRows.length)
    _self.addOrderJobsMapSource(mapObject, sortableRows);
  }

  /**
   * @deprecated https://chromestatus.com/feature/5083947249172480
   * */
  unregisterOrderJobsChanged() {
    $("body").off("DOMSubtreeModified", "#card_others_order_jobs");
    $("body").off("DOMSubtreeModified", "#card_driver_order_jobs");
  }

  unregisterOrderJobsChangedMutation() {
    this.cardOthersOrderJobsObserver.disconnect();
    this.cardDriverOrderJobsObserver.disconnect();
  }

  /** =====   ACTIONS ===== */
  /**
   * Handler for pull order_jobs
   */

  focusOnOrderJob(event) {
    event.preventDefault();
    demand_plannings_map.flyTo({
      center: [event.currentTarget.dataset.longitude, event.currentTarget.dataset.latitude],
      zoom: 18,
    });
  }

  addOrderJobsMapSource(mapObject, rows) {
    let source = mapObject.getSource('orderJobs');

    if(!source){
      mapObject.addSource('orderJobs', this.convertOrderJobsToMapSource(rows));
    }else{
      source.setData(this.convertOrderJobsToFeatureCollection(rows));
    }
  }

  addOrderJobClusterLayer(mapObject) {
    mapObject.addLayer({
      id: 'order-jobs-cluster',
      type: 'circle',
      source: 'orderJobs',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    });
  }

  addOrderJobClusterCountLayer(mapObject) {
    mapObject.addLayer({
      id: 'order-jobs-cluster-count',
      type: 'symbol',
      source: 'orderJobs',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
  }

  addUnclusteredPointsLayerShortTitle(mapObject) {
    mapObject.addLayer({
      id: 'order-jobs-cluster-count-short-title',
      type: 'symbol',
      source: 'orderJobs',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'text-field': ['get', 'short_title'],
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
  }

  addUnclusteredPointsLayer(mapObject) {
    var _self = this;

    mapObject.addLayer({
      id: 'order-jobs-unclustered-point',
      type: 'circle',
      source: 'orderJobs',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': ['get', 'bg_color'],
        'circle-radius': 10,
        'circle-stroke-color': '#000000',
        'circle-stroke-width': 2
      }
    });

    mapObject.on('click', 'order-jobs-unclustered-point', function (e) {
      _self.scrollToOrderJobAndFocus(e.features[0].properties.id);
    });
  }

  initializeSpiderLeg(spiderLeg) {
    var _self = this;
    var spiderLegContainer = spiderLeg.elements.container;
    var pinElem = spiderLeg.elements.pin;
    var feature = spiderLeg.feature;
    var shortTitle = feature.short_title;
    var bgColor = feature.bg_color;

    spiderLegContainer.className = spiderLegContainer.className + ' custom-leg-container ';

    var div = document.createElement('div');
    div.style.width = '25px';
    div.style.height = '25px';
    div.style.borderRadius = '50%';
    div.style.backgroundColor = bgColor;
    div.style.color = '#000000';
    div.style.fontSize = '12px';
    div.style.fontFamily = 'Arial';
    div.style.textAlign = 'center';
    div.style.fontWeight = 'bold';
    div.style.border = '2px solid #000000';
    div.innerText = shortTitle;


    var $spiderPinCustom = $("<div></div>");
    $spiderPinCustom.append(div);

    $(pinElem).append($spiderPinCustom);
    $spiderPinCustom.css({
      'position': 'relative',
      'width': '30px',
      'height': '30px',
      'margin-left': '-15px',
      'margin-top': '-15px',
      'font-size': '12px'
    });

    if ($(pinElem).length) {
      $(pinElem).on('click', function(e){
        _self.scrollToOrderJobAndFocus(spiderLeg.feature.id);
        e.stopPropagation();
      });
    }
  }


  scrollToOrderJobAndFocus(order_job_id) {
    let selector = "#oj-"+order_job_id;
    $(selector).css('background-color', 'rgba(37, 150, 190, 0.6)');

    $(selector)[0].scrollIntoView({
      behavior: "smooth",
      block: "start"
    });

    $(selector).delay(100).fadeOut().fadeIn('slow').fadeOut().fadeIn('slow', function() {
      $(this).css('background-color', 'initial');
    });
  }

  setupSpiderifierOnMap(mapObject, mapCenter) {
    var _self = this;
    // SPIDERFY
    var spiderifier = new MapboxglSpiderifier(mapObject, {
          customPin: true,
          // animate: true,
          // animationSpeed: 100,
          onClick: function(e, spiderLeg){
            //none, utilize o initializeSpiderLeg
          },
          markerWidth: 48,
          markerHeight: 48,
          initializeLeg: this.initializeSpiderLeg.bind(this)
        }),
        SPIDERFY_FROM_ZOOM = 14,
        features = _.map(_.range(10000), function(index){
          return {
            type: 'feature',
            properties: {id: index},
            geometry: {
              type: 'Point',
              coordinates: [mapCenter[0] + (_.random(1000) * 0.001), mapCenter[1] + (_.random(1000) * 0.001)]
            }
          }
        });

    //Map related events
    mapObject.on('click', function(e){
      var features = mapObject.queryRenderedFeatures(e.point, {
        layers: ['order-jobs-cluster']
      });

      spiderifier.unspiderfy();
      if (!features.length) {
        return;
      } else if (demand_plannings_map.getZoom() < SPIDERFY_FROM_ZOOM) {
        mapObject.easeTo({center: e.lngLat, zoom: mapObject.getZoom() + 2});
      } else {
        mapObject.getSource('orderJobs').getClusterLeaves(
            features[0].properties.cluster_id,
            100,
            0,
            function(err, leafFeatures){
              if (err) {
                return console.error('error while getting leaves of a cluster', err);
              }
              var markers = _.map(leafFeatures, function(leafFeature){
                return leafFeature.properties;
              });
              spiderifier.spiderfy(features[0].geometry.coordinates, markers);

              $('.custom-leg-container').each(function(i, dom){
                //workaround para remover blur devido ao rotate no spider leg container
                dom.style.transform = dom.style.transform.replace('translate(-50%, -50%)', '')
              });

            }
        );
      }
    });
    mapObject.on('mousemove', function(e) {
      var features = mapObject.queryRenderedFeatures(e.point, {
        layers: ['order-jobs-cluster']
      });
      mapObject.getCanvas().style.cursor = (features.length) ? 'pointer' : '';
    });
    mapObject.on('zoomstart', function(){
      spiderifier.unspiderfy();
    });

  }


  convertOrderJobsToMapSource(order_job_table_rows) {
    return {
      type: 'geojson',
      data: this.convertOrderJobsToFeatureCollection(order_job_table_rows),
      cluster: true,
      clusterMaxZoom: 25,
      clusterRadius: 50
    };
  }

  convertOrderJobsToFeatureCollection(order_job_table_rows) {
    var features = [];
    for (var row of order_job_table_rows) {
      features.push(JSON.parse(row.dataset.geojson));
    }

    return {
      "type": "FeatureCollection",
      "features": features
    };
  }

  createPositionMarker(position, mapObject, feature) {
    var el = document.createElement('div');

    el.className = "custom-marker-" + feature.properties.order_job_type;
    el.id = "order-job-driver-" + feature.properties.id;
    el.innerHTML = position;

    new mapboxgl.Marker(el)
        .setLngLat(feature.geometry.coordinates)
        .addTo(mapObject);
  }
}
