import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { put } from "@rails/request.js"

export default class extends Controller {
  static targets = ["sortableVertical", "sortableHorizontal", "arrowContainer"]
  static values = {
    driverId: String,
    performAtDay: String
  }

  connect(event) {
    this.sortable(event);
    this.eventsMsgMouse();
  }

  sortable(event) {
    let verticalConfigs = {
      onEnd: this.end.bind(this),
      direction: 'vertical',
      filter: '#driver_no_records_found',
      group: 'vertical',
      ghostClass: 'warning-background-class'
    }

    let verticalItems = this.sortableVerticalTarget.querySelectorAll('tr.sortable');
    if (verticalItems.length > 0) {
      verticalConfigs.handle = ".handle-sortable-vertical";
    }

    new Sortable(this.sortableVerticalTarget, verticalConfigs);

    let horizontalConfigs = {
      onStart: this.start.bind(this),
      onEnd: this.end.bind(this),
      animation: 50,
      filter: '#others_no_records_found',
      sort: false,
      group: {
        name: 'vertical',
        put: false
      },
      ghostClass: 'left-warning-background-class'

    }

    let horizontalItems = this.sortableHorizontalTarget.querySelectorAll('tr.sortable');
    if (horizontalItems.length > 0) {
      horizontalConfigs.handle = ".handle-sortable-horizontal";
    }

    new Sortable(this.sortableHorizontalTarget, horizontalConfigs);
  }

  start(event) {
    this.showArrowContainer();
    this.disableNoRecords();
    this.showMsgToStart(event);
  }

  end(event) {
    this.hideArrowContainer();
    if(event.from.id!=event.to.id ||
      (event.to.id=="list_driver_order_jobs" && event.newIndex !== event.oldIndex)) {
      this.handleMove(event);
    }

    this.enableNoRecords();
    this.hideMsgToStart(event);
  }

  async handleMove(event) {
    let redirectUrl = event.item.dataset.redirectUrl;
    let turboFrame = event.item.dataset.turboFrame;
    let action = event.item.dataset.action;
    let position = event.newIndex;

    let verticalItems = this.sortableVerticalTarget.querySelectorAll('tr.sortable');
    if(event.from.id!=event.to.id && verticalItems.length == 1) {
      position = position-1;
    }

    let id = event.item.dataset.id;
    let url = '/partner_area/operational/demand_plannings/:id/move'.replace(":id", id)

    let params = {
      id: id,
      redirect_url: redirectUrl,
      turbo_frame: turboFrame,
      move_action: action,
      position: position,
      driver_id: this.driverIdValue,
      perform_at_day: this.performAtDayValue
    };

    await put(url, {
      body: JSON.stringify(params),
      responseKind: "turbo-stream"
    })
  }

  disableNoRecords() {
    let verticalItems = this.sortableVerticalTarget.querySelectorAll('tr.sortable');
    if (verticalItems.length == 0) {
      $('#driver_no_records_found').addClass("d-none")
    }
  }

  enableNoRecords() {
    let verticalItems = this.sortableVerticalTarget.querySelectorAll('tr.sortable');
    if (verticalItems.length == 0) {
      $('#driver_no_records_found').removeClass("d-none")
    }
  }

  eventsMsgMouse() {
    $('.handle-sortable-horizontal').on('mousedown', function(event) {

      $(this).css('cursor', 'grabbing');
    });

    $('.handle-sortable-horizontal').on('mouseup', function(event) {
      let parentElement = $(this).closest('.tr-parent');
      let badgeMsgElement = parentElement.find('.badge-msg');
      badgeMsgElement.addClass('d-none');

      $(this).css('cursor', 'grab');
    });
  }

  hideMsgToStart(event) {
    let badgeMsgElement = $(event.item).find('.badge-msg');
    badgeMsgElement.addClass('d-none');

    $(event.item).find('.handle-sortable-horizontal').css('cursor', 'grab');
  }

  showMsgToStart(event) {
    const trWidth = $('#card_others_order_jobs').width();
    $('.badge-parent').width(trWidth);

    let parentElement = $(event.item).closest('.tr-parent');
    let badgeMsgElement = parentElement.find('.badge-msg');
    badgeMsgElement.removeClass('d-none');
  }

  showArrowContainer() {
    this.arrowContainerTarget.classList.remove("d-none");
  }

  hideArrowContainer() {
    this.arrowContainerTarget.classList.add("d-none");
  }
}
