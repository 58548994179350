// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
import "@hotwired/turbo-rails"
import TurboPower from 'turbo_power'
require("@rails/activestorage").start();
require("channels");

TurboPower.initialize(Turbo.StreamActions) //Turbo Power Boost

// Leaflet MapBox
// import {movingMarker, MovingMarker} from "../leaflet/moving-marker.js";

// JS
import "jquery"
import "jquery-ui";
import "bootstrap";
import "bootstrap-select";
import "daemonite-material";
import "jquery-mask-plugin";
import "controllers";
import cropper from "cropper";


// SELECT2 Plugin Jquery
import "select2/dist/js/select2.full";
import "select2/dist/js/i18n/pt-BR";
import "select2/dist/js/i18n/en";

// lodash
import _ from "lodash";

//moment
import moment from "moment";

// ApexCharts
import ApexCharts from "apexcharts";
window.ApexCharts = ApexCharts;

window._ = _;
window.moment = moment;

// Active Storage
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

// active storage progress bar turbo
import '../file_input'

// events
import '../events'

// Images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

