import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import Rails from '@rails/ujs'

export default class extends CheckboxSelectAll {
    static values = {
        currentStatus: String
    }

    static targets = [
                      "bulkActionsMenu", 
                      "checkedCounter", 
                      "failedDropdownAction", 
                      "doneDropdownAction", 
                      "startedDropdownAction", 
                      "rescheduleDropdownAction", 
                      "assignDriverDropdownAction", 
                      "arriveDropdownAction",
                      "newOrderForAssetReplaceDropdownAction",
                      "cancelDropdownAction",
                      "collectForBucketAndOrderForAssetReplacement",
                      "createCollectDropdownAction"
                     ]

    connect() {
        super.connect()
        // Get all checked checkboxes
        this.checked
        // Get all unchecked checkboxes
        this.unchecked
    }

    handleSingleSelect(event) {
        if(this.checked.length > 0) {
          this.showMenu()
          this.showButtonMenuAction(this.checked)
        } else {
            this.hideMenu()
        }
        this.updateCheckedCount();
    }

    handleSelectAll(event) {
        if(event.target.checked) {
            let self = this;
            self.updateCheckedCount(this.checked.length+this.unchecked.length);
            self.showMenu();
            self.showButtonMenuAction(this.checkboxTargets);
        } else {
            this.updateCheckedCount();
            this.hideMenu()
        }
    }

    done(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doAjaxRequest('done', this.checked, this.getRedirectUrlParam(event));
        }
    }

    failure(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doAjaxRequest('failure', this.checked, this.getRedirectUrlParam(event));
        }
    }

    start(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0 ) {
            this.doAjaxRequest('start', this.checked, this.getRedirectUrlParam(event));
        }
    }

    showCreateCollect(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalOrderItemAjaxRequest("new_collect_for_bucket_order_item", this.checked, this.getRedirectUrlParam(event));
        }
    }

    showModalBulkPerformAt(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("bulk_perform_at", this.checked, this.getRedirectUrlParam(event), this.getUserTypesParam(event));
        }
    }

    showModalBulkReschedule(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("bulk_reschedule", this.checked, this.getRedirectUrlParam(event));
        }
    }

    showModalBulkAssignDriver(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("bulk_assign_driver", this.checked, this.getRedirectUrlParam(event), this.getShowMessageParam(event));
        }
    }

    showModalBulkNewOrderForAssetReplacement(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("show_bulk_new_order_for_asset_replacement", this.checked, this.getRedirectUrlParam(event, false));
        }
    }

    showCollectForBucketAndOrderForAssetReplacement(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalOrderItemAjaxRequestGet("show_collect_for_bucket_and_order_for_asset_replacement", this.checked, this.getRedirectUrlParam(event, false));
        }
    }

    arrive(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doAjaxRequest("arrive", this.checked, this.getRedirectUrlParam(event));
        }
    }
    
    updateCheckedCount(count) {
        this.checkedCounterTarget.innerHTML = count || this.checked.length;
    }
 
    showMenu() {
        this.bulkActionsMenuTarget.classList.remove("d-none");
    }
    
    hideMenu() {
        this.bulkActionsMenuTarget.classList.add("d-none");
    }

    cancelCollect(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doCancelOrderItemAjaxRequest("bulk_cancel", this.checked, this.getRedirectUrlParam(event));
        }
    }

    doAjaxRequest(action, checkboxes, redirectUrl) {
        let data = new FormData();
        let orderJobsIds = this.getOrderJobIds(checkboxes);
        orderJobsIds.forEach((oj) => data.append("bulk_ids[]", oj));
        data.append("redirect_url", redirectUrl);
        Rails.ajax({
            type: "PUT",
            url: 'order_jobs/bulk/'+action,
            data: data
        });
    }

    doOrderItemAjaxRequest(action, checkboxes, redirectUrl) {
        let data = new FormData();
        let orderJobsIds = this.getOrderJobIds(checkboxes);
        orderJobsIds.forEach((oj) => data.append("bulk_ids[]", oj));
        data.append("redirect_url", redirectUrl);
        Rails.ajax({
            type: "PUT",
            url: 'order_items/'+ checkboxes[0].value +'/order_jobs/'+ action,
            data: data
        });
    }

    doCancelOrderItemAjaxRequest(action, checkboxes, redirectUrl) {
        let params = new URLSearchParams();
        let orderJobsIds = this.getOrderJobIds(checkboxes);
        checkboxes.forEach((checkbox) => params.append("bulk_ids[]", checkbox.value));
        params.append("redirect_url", redirectUrl);
        Rails.ajax({
            type: "PUT",
            url: 'order_items/'+ checkboxes[0].value +'/order_jobs/' + orderJobsIds[0] + '/' + action,
            data: params
        });
    }
    
    doShowModalAjaxRequest(action, checkboxes, redirectUrl, userTypes, showMessage = true) {
        let params = new URLSearchParams();
        let orderJobsIds = this.getOrderJobIds(checkboxes);
        orderJobsIds.forEach((oj) => params.append("bulk_ids[]", oj));
        params.append("redirect_url", redirectUrl);
        params.append("show_info_message", showMessage);
        params.append("user_types[]", userTypes);
        params.append("current_status", this.currentStatusValue);

        Rails.ajax({
            type: "GET",
            url: 'order_jobs/bulk/'+action,
            data: params
        });
    }

    doShowModalOrderItemAjaxRequest(action, checkboxes, redirectUrl, showMessage = true) {
        let params = new URLSearchParams();
        let orderJobsIds = this.getOrderJobIds(checkboxes);
        checkboxes.forEach((checkbox) => params.append("bulk_ids[]", checkbox.value));
        params.append("redirect_url", redirectUrl);
        params.append("show_info_message", showMessage);
        orderJobsIds.forEach((orderJobs) => params.append("order_jobs[]", orderJobs)) 

        Rails.ajax({
            type: "GET",
            url: 'order_items/'+ checkboxes[0].value +'/order_jobs/'+ action,
            data: params
        });
    }

    doShowModalOrderItemAjaxRequestGet(action, checkboxes, redirectUrl, showMessage = true) {
        let params = new URLSearchParams();
        let orderJobsIds = this.getOrderJobIds(checkboxes);
        checkboxes.forEach((checkbox) => params.append("bulk_ids[]", checkbox.value));
        params.append("redirect_url", redirectUrl);
        params.append("show_info_message", showMessage);
        orderJobsIds.forEach((orderJobs) => params.append("order_jobs[]", orderJobs))

        Rails.ajax({
            type: "GET",
            url: 'order_jobs/bulk/'+action,
            data: params
        });
    }

    getOrderJobIds(checkboxes) {
        let ids = [];
        checkboxes.forEach((checkbox) => ids.push(checkbox.dataset['orderJobId']));
        return ids;
    }

    getRedirectUrlParam(event) {
        return event.currentTarget.dataset['redirectUrlParam'];
    }
    
    getConfirmMsgParam(event) {
        return event.currentTarget.dataset['confirmMsgParam'];
    }

    getShowMessageParam(event) {
        return event.currentTarget.dataset['showMessageParam'];
    }

    getUserTypesParam(event) {
        return event.currentTarget.dataset['userTypes'];
    }

    // Desabilitar Ação
    showButtonMenuAction(checkboxes) {
        let hasFutureOrderJob = false;
        let orderItemStatus = false;
        let showActionsStatus = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            showActionsStatus = button.dataset['showActionsStatus'];
            if (!breakForEach) {
                hasFutureOrderJob = button.dataset['futurePerformAt']==='true';
                orderItemStatus = button.dataset['orderItemStatus']?.toString();
                if(hasFutureOrderJob) breakForEach = true;
            }
        });

        if (showActionsStatus==='true'){
            if(hasFutureOrderJob){
                //desabilitar
                //Atendido
                this.disableDropdownAction(this.doneDropdownActionTarget)
                //Em rota
                this.disableDropdownAction(this.startedDropdownActionTarget)
                //Falhou
                this.disableDropdownAction(this.failedDropdownActionTarget);
            } else {
                //Em rota
                this.showActionStarted(checkboxes)
                //Atendido
                this.showActionDone(checkboxes)
                //Falhou
                this.showActionFailed(checkboxes)
            }
        }

        // Reagendar
        this.showActionReschedule(checkboxes)

        if (showActionsStatus==='true'){
            // Atribuir Responsável
            this.showActionAssignDriver(checkboxes)
            // Chegou
            this.showActionArrive(checkboxes)
        }

        this.showActionCancel(checkboxes);
        this.showNewOrderForAssetReplacement(checkboxes);
        this.showCollectBucketOrderForAssetReplacement(checkboxes);
        this.showCreateCollectDropdownAction(checkboxes);
    }

    // Em rota
    showActionStarted(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['showStarted']==="false";
                if(notShow) breakForEach = true;
            }
        });

        if(notShow){
          //desabilitar
            this.disableDropdownAction(this.startedDropdownActionTarget)
        } else {
            this.enableDropdownAction(this.startedDropdownActionTarget);
        }
    }
    
    // Atendido
    showActionDone(checkboxes) {
        let notShowDone = false;
        let breakForEachDone = false;

        (checkboxes).forEach((button) => {
            if (!breakForEachDone) {
                notShowDone = button.dataset['showDone']==="false";
                if(notShowDone) breakForEachDone = true;
            }
        });

        let notShowDoneForPlaced = false;
        let breakForEachDoneForPlaced = false;

        (checkboxes).forEach((button) => {
            if (!breakForEachDoneForPlaced) {
                notShowDoneForPlaced = button.dataset['showDoneForPlaced']==="false";
                if(notShowDoneForPlaced) breakForEachDoneForPlaced = true;
            }
        })
        
        if (!notShowDone) {
            this.enableDropdownAction(this.doneDropdownActionTarget);
            this.currentStatusValue = null
        } else if (!notShowDoneForPlaced) {
            this.enableDropdownAction(this.doneDropdownActionTarget);
            this.currentStatusValue = 'placed'
        } else {
            //desabilitar
            this.disableDropdownAction(this.doneDropdownActionTarget);
        }
    }
    
    // Falhou
    showActionFailed(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['showFailed']==="false";
                if(notShow) breakForEach = true;
            }
        });

        if (notShow) {
            //desabilitar
            this.disableDropdownAction(this.failedDropdownActionTarget)
        } else {
            this.enableDropdownAction(this.failedDropdownActionTarget);
        }
    }

    // Cancelou
    showActionCancel(checkboxes) {
        let notShow = false;
        let show = false;
        let breakForEach = false;
        let orderItemStatus = null;

        (checkboxes).forEach((button) => {
            show = button.dataset['showCancel']==="true";
            if (!breakForEach) {
                orderItemStatus = button.dataset['orderItemStatus']?.toString()
                notShow = orderItemStatus!="to_collect";
                if(notShow) breakForEach = true;
            }
        });

        if (show) {
            if (notShow) {
                //desabilitar
                this.disableDropdownAction(this.cancelDropdownActionTarget)
            } else {
                this.enableDropdownAction(this.cancelDropdownActionTarget);
            }
        }

    }
    
    // Atribuir Responsável
    showActionAssignDriver(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['showAssignDriver']==="false";
                if(notShow) breakForEach = true;
            }
        });

        if (notShow) {
            //desabilitar
            this.disableDropdownAction(this.assignDriverDropdownActionTarget)
        } else {
            this.enableDropdownAction(this.assignDriverDropdownActionTarget);
        }
    }

    // Reagendar
    showActionReschedule(checkboxes) {
        let notShow = false;
        let breakForEach = false;
        let showActions = false;

        (checkboxes).forEach((button) => {
            showActions = button.dataset['showActionsReschedule']==="false";

            if (!breakForEach) {
                notShow = button.dataset['showReschedule']==="false";
                if(notShow) breakForEach = true;
            } 
        });

        if (!showActions){
            if (notShow) {
                //desabilitar
                this.disableDropdownAction(this.rescheduleDropdownActionTarget)
            } else {
                this.enableDropdownAction(this.rescheduleDropdownActionTarget);
            }
        }
    }

    // Chegou
    showActionArrive(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['showArrive']==="false";
                if(notShow) breakForEach = true;
            }
        });

        if (notShow) {
            //desabilitar
            this.disableDropdownAction(this.arriveDropdownActionTarget)
        } else {
            this.enableDropdownAction(this.arriveDropdownActionTarget);
        }
    }

    //Novo item de troca
    showNewOrderForAssetReplacement(checkboxes) {
        let notShow = false;
        let breakForEach = false;
        let not_permits_order_asset_replacement = false;

        (checkboxes).forEach((button) => {
            notShow = button.dataset['showNewOrderForAssetReplacement']==="false";

            if (!breakForEach) {
                not_permits_order_asset_replacement = button.dataset['permitsOrderAssetReplacement']==="false";
                if(not_permits_order_asset_replacement) breakForEach = true;
            }
        });

        if (!notShow) {
            if (not_permits_order_asset_replacement) {
                //desabilitar
                this.disableDropdownAction(this.newOrderForAssetReplaceDropdownActionTarget)
            } else {
                this.enableDropdownAction(this.newOrderForAssetReplaceDropdownActionTarget);
            }
        }
    }

    //Agendar Coleta com Solicitação para Troca
    showCollectBucketOrderForAssetReplacement(checkboxes) {
        let notShow = false;
        let notShowForStatus = false;
        let breakForEach = false;
        let orderItemStatus = null;

        (checkboxes).forEach((button) => {
            notShow = button.dataset['showCollectBucketOrderForAssetReplacement']==="false";

            if (!breakForEach) {
                orderItemStatus = button.dataset['orderItemStatus']?.toString();
                notShowForStatus = orderItemStatus!='on_site'
                if(notShowForStatus) breakForEach = true;
            }
        });

        if (!notShow) {
            if (notShowForStatus) {
                //desabilitar
                this.disableDropdownAction(this.collectForBucketAndOrderForAssetReplacementTarget)
            } else {
                this.enableDropdownAction(this.collectForBucketAndOrderForAssetReplacementTarget);
            }
        }
    }

    //Agendar Coleta
    showCreateCollectDropdownAction(checkboxes) {
        let notShow = false;
        let notShowForStatus = false;
        let breakForEach = false;
        let orderItemStatus = null;

        (checkboxes).forEach((button) => {
            notShow = button.dataset['showCreateCollect']==="false";

            if (!breakForEach) {
                orderItemStatus = button.dataset['orderItemStatus']?.toString();
                notShowForStatus = orderItemStatus!='on_site'
                if(notShowForStatus) breakForEach = true;
            }
        });

        if (!notShow) {
            if (notShowForStatus) {
                //desabilitar
                this.disableDropdownAction(this.createCollectDropdownActionTarget)
            } else {
                this.enableDropdownAction(this.createCollectDropdownActionTarget);
            }
        }
    }

    disableDropdownAction(target) {
        let enabled = target?.getElementsByClassName("enabled-option");
        let disabled = target?.getElementsByClassName("disabled-option");

        enabled[0]?.classList.add("d-none");
        disabled[0]?.classList.remove("d-none");
    }

    enableDropdownAction(target) {
        let enabled = target?.getElementsByClassName("enabled-option");
        let disabled = target?.getElementsByClassName("disabled-option");

        enabled[0]?.classList.remove("d-none");
        disabled[0]?.classList.add("d-none");
    }
}