import ApplicationController from "../application_controller";
import StimulusReflex from "stimulus_reflex";
import consumer from "../../channels/consumer";
import CableReady from "cable_ready";
import { driverMarker, Colors } from "../../mapbox-gl";

function clog(it) {
  if (debug_map) {
    console.log(it);
  }
}

export default class extends ApplicationController {
  static targets = [ "metadata" ]
  connect() {
    this.element[this.identifier] = this;
    StimulusReflex.register(this);
    // this.initializeOrderJobFilterSelect2();
  }

  disconnect() {
    this.unregisterChannels();
    this.unregisterCustomDomEvents();
    // this.unregisterOrderJobsChanged();
    this.unregisterOrderJobsChangedMutation();
    this.unregisterUsersChanged();
  }

  setup(map, mapCenter) {
    // called when map is ready
    clog('Controller SETUP');
    this.registerChannels();
    this.registerCustomDomEvents();
    this.setupOrderJobsOnMap(map, mapCenter);
    this.setupDriversOnMap();
    this.registerOrderJobsChangedMutation(map, mapCenter);
    // this.registerOrderJobsChanged(map, mapCenter);
    this.registerUsersChanged();
  }

  registerChannels() {
    for (var label in channels) {
      clog("creating subscription for " + channels[label]);
      subscriptions.push(
        consumer.subscriptions.create(
          { channel: channels[label], partner_id: partner_id },
          {
            received(data) {
              clog("Received on channel ");
              clog(data);
              if (data.cableReady) CableReady.perform(data.operations);
            },
          }
        )
      );
    }

    clog("creating subscription for PartnerChannel");
    subscriptions.push(
      //partner
      consumer.subscriptions.create(
        { channel: "PartnerChannel", partner_id: partner_id },
        {
          received(data) {
            clog("Received on channel ");
            clog(data);
            if (data.cableReady) CableReady.perform(data.operations);
          },
        }
      )
    );
  }

  unregisterChannels() {
    for (var sub in subscriptions) {
      clog("removing subscriptions ");
      clog(sub);
      consumer.subscriptions.remove(sub);
    }
  }

  registerCustomDomEvents() {
    var _self = this;
    clog(
      "registering event " +
      "partner_" +
      partner_id +
      ":operational_map:pull_order_jobs"
    );
    $("#operational-map-controller").bind(
      "partner_" + partner_id + ":operational_map:pull_order_jobs",
      function (evt) {
        $("#refresh_order_jobs_submit").trigger("click");
      }
    );

    clog("registering event operational_map:view_order_job");
    $("#operational-map-controller").bind(
      "operational_map:view_order_job",
      function (evt) {
        $("#show-order-job-modal-" + evt.detail.order_job.id)[0].click();
      }
    );

    clog("registering event users:pull_users");
    $("#operational-map-controller").bind("users:pull_users", function (evt) {
      clog("will pull users");
      $("#refresh_drivers_submit").trigger("click");
    });
  }

  unregisterCustomDomEvents() {
    clog(
      "UNregistering event " +
      "partner_" +
      partner_id +
      ":operational_map:pull_order_jobs"
    );
    $("#operational-map-controller").off(
      "partner_" + partner_id + ":operational_map:pull_order_jobs"
    );
    clog("UNregistering event operational_map:view_order_job");
    $("#operational-map-controller").off("operational_map:view_order_job");
    clog("UNregistering users:pull_users");
    $("#operational-map-controller").off("users:pull_users");
  }

  registerUserEvents(user_id) {
    var _self = this;

    clog("registering event " + "user_" + user_id + ":new_driver_position");
    $("#operational-map-controller").bind(
      "user_" + user_id + ":new_driver_position",
      function (evt) {
        clog("new driver positions");
        clog(evt.detail);
        if(!evt.detail) return;
        var trackings = evt.detail.trackings;

        if (trackings && trackings.length > 0) {
          _self.drawMovingDriverOnMap({
            user_id: user_id,
            user: trackings[0].user,
            tracking_infos: trackings,
          });
        }
      }
    );

    //register cable ready hooks for user channel
    var user_subscription = consumer.subscriptions.create(
      { channel: "UserChannel", user_id: user_id },
      {
        received(data) {
          clog("Received on channel for user");
          if (data.cableReady){
            clog(data.operations);
            CableReady.perform(data.operations);
          }
        },
      }
    );
    users_subscriptions[user_id] = user_subscription;
  }

  unregisterUserEvents(user_id) {
    $("#operational-map-controller").unbind(
      "user_" + user_id + ":new_driver_position"
    );
    consumer.subscriptions.remove(users_subscriptions[user_id]);
  }

  /**
   * @deprecated https://chromestatus.com/feature/5083947249172480
   * */
  registerOrderJobsChanged(mapObject, mapCenter) {
    var _self = this;
    $("body").on("DOMSubtreeModified", "#order_jobs_hidden_container", function (
      event
    ) {
      clog("order_jobs list changed");
      var rows = $("#order_jobs_hidden_container").find("tbody").find("tr");
      clog('OJ Number of rows: '+ rows.length)
      _self.addOrderJobsMapSource(mapObject, rows);
    });
  }

  registerOrderJobsChangedMutation(mapObject, mapCenter){
    var _self = this;
    const targetNode = document.getElementById("order_jobs_hidden_container");
    const config = { attributes: false, childList: true, subtree: true };
    const observer = new MutationObserver(function(mutationList, observer){
      clog("order_jobs list changed");
      var rows = $("#order_jobs_hidden_container").find("tbody").find("tr");
      clog('OJ Number of rows: '+ rows.length)
      _self.addOrderJobsMapSource(mapObject, rows);
    });
    observer.observe(targetNode, config);
    this.orderJobsObserver = observer;
  }

  unregisterOrderJobsChanged() {
    $("body").off("DOMSubtreeModified", "#order_jobs_hidden_container");
  }

  unregisterOrderJobsChangedMutation() {
    this.orderJobsObserver.disconnect();
  }

  registerUsersChanged() {
    var _self = this;
    //para quando surgir um user novo ou um user for removido
    $("body").on("DOMSubtreeModified", "#drivers-container-hidden", function (
        event
    ) {
      clog("drivers list changed");
      var rows = $("#drivers-container-hidden").find("#drivers_tbody").find("tr");
      clog('DRV Number of rows: '+ rows.length)
      let updated = [];
      for (var row of rows) {

        var user = JSON.parse(row.dataset.jsonuser);
        clog('Updated user ' + user.id + ' from drivers hidden table')
        updated.push(user);

        //já tem marker criado?
        if( _.find(drivers_markers, { user_id: user.id }) ) {
          continue; // não precisa desenhar, nem registrar eventos
        }

        var tracking = null;
        if(row.dataset.json!==''){
          tracking = JSON.parse(row.dataset.json);
        }

        if (tracking) {
          _self.drawMovingDriverOnMap({
            user_id: row.dataset.userid,
            user: user,
            tracking_infos: [
              {
                //last known tracking info
                user_id: row.dataset.userid,
                user: user,
                tracking: JSON.parse(row.dataset.json),
              },
            ],
          })[0];
        }
        if (!users_subscriptions[row.dataset.userid]) {
          _self.registerUserEvents(row.dataset.userid);
        }
      }

      // remove orphans
      // PROBLEMA: morph dom dispara este evento 2x (ao remover o item e ao adicionar)
      // for (var m of drivers_markers) {
      //   if (!_.find(updated, { id: m.user_id })) {
      //     clog('Could not find marker on updated rows ', m);
      //     m.marker.remove();
      //     _self.unregisterUserEvents(m.user_id);
      //     _.remove(drivers_markers, { user_id: m.user_id });
      //   }
      // }
    });
  }

  unregisterUsersChanged() {
    $("body").off("DOMSubtreeModified", "#drivers-container-hidden");
  }

  //First Setup
  setupOrderJobsOnMap(map, mapCenter) {
    var _self = this;
    var rows = $("#order_jobs_hidden_container").find("tbody").find("tr");

    this.addOrderJobsMapSource(map, rows);
    this.addOrderJobClusterLayer(map);
    this.addOrderJobClusterCountLayer(map);
    this.addUnclusteredPointsLayer(map);
    this.setupSpiderifierOnMap(map, mapCenter);
  }

  setupDriversOnMap() {
    clog('SETUP drivers on map')
    var rows = $("#drivers-container-hidden").find("#drivers_tbody").find("tr");
    for (var row of rows) {
      if (row.dataset.jsonuser !== "" && row.dataset.json !== "") {
        var user = JSON.parse(row.dataset.jsonuser);
        this.drawMovingDriverOnMap({
          user_id: row.dataset.userid,
          user: user,
          tracking_infos: [
            {
              //last known tracking info
              user_id: row.dataset.userid,
              user: user,
              tracking: JSON.parse(row.dataset.json),
            },
          ],
        });
      }
      this.registerUserEvents(row.dataset.userid);
    }
  }
  //__First Setup

  /** =====   ACTIONS ===== */

  /**
   * Handler for pull order_jobs
   */
  pullOrderJobs(event) {

    if(event.type=='keyup') {
      if(event.keyCode === 13) {
        event.preventDefault();
      }else{
        return;
      }
    }

    event.preventDefault();
    this.showProgress();
    clog("fetching order jobs..");
    this.stimulate(
      "PartnerArea::OperationalMapReflex#pull_order_jobs",
      event.target
    );
  }

  pullDrivers(event) {
    event.preventDefault();
    this.showProgress();
    clog("fetching drivers..");
    this.stimulate(
      "PartnerArea::OperationalMapReflex#pull_drivers",
      event.target
    );
  }

  expandDriver(event) {
    event.preventDefault();
    var marker = _.find(drivers_markers, {
      user_id: event.currentTarget.dataset.userid,
    });
    if (marker) {
      marker.expanded = !marker.expanded;
    }
  }

  focusOnDriver(event) {
    event.preventDefault();
    var marker = _.find(drivers_markers, {
      user_id: event.currentTarget.dataset.userid,
    });
    if (marker) {
      var tracking = marker.tracking_info.tracking;
      operational_map.flyTo({
        center: [tracking.longitude, tracking.latitude],
        zoom: 18,
      });
    }
  }

  focusOnOrderJob(event) {
    event.preventDefault();
    operational_map.flyTo({
      center: [event.currentTarget.dataset.longitude, event.currentTarget.dataset.latitude],
      zoom: 18,
    });
  }

  toggleFullscreen(event) {
    event.preventDefault();
    if ($("#operational-map-controller").hasClass("make-fullscreen")) {
      //exit fs
      $("#operational-map-controller").removeClass("make-fullscreen");
      $("#card-operational").removeClass("d-none");
      $("#card-operational-tabs").removeClass("d-none");
      $("#card-operational-map").removeClass("w-100");
      $("#card-operational-map").addClass("w-75 p-3");
      $("#order_jobs_filter").removeClass("d-none");
      $("#form-full-screen").addClass("d-none");
      $("#fullscreen-logo").addClass("d-none");
    } else {
      $("#operational-map-controller").addClass("make-fullscreen");
      $("#card-operational").addClass("d-none");
      $("#card-operational-tabs").addClass("d-none");
      $("#card-operational-map").removeClass("w-75 p-3");
      $("#card-operational-map").addClass("w-100");
      $("#form-full-screen").removeClass("d-none");
      $("#fullscreen-logo").removeClass("d-none");
      $("#order_jobs_filter").addClass("d-none");
    }
    operational_map.resize();
  }

  /** =====   DRIVER ===== */

  /**
   * Desenha a primeira vez ou atualiza a posição do marcador no mapa
   * @param options
   * @returns {(*|{color: *, user_id: *, marker: *, tracking_info: *, tracking_id: *})[]}
   */
  drawMovingDriverOnMap(options) {
    var _self = this;
    const { user_id, user, tracking_infos } = options;

    var driver_marker = _.find(drivers_markers, { user_id: user_id });

    // tracking_info: { tracking:{}, user:{}, user_id: '', vehicle:{}}
    var last = _.last(tracking_infos);
    if (driver_marker) {
      // existe
      clog("drawing existing driver marker.." + user_id);
      driver_marker.tracking_info = last;
      driver_marker.tracking_id = last.tracking.id;
      this.redrawDriverMarker(driver_marker, tracking_infos);
    } else {
      //novo
      clog("drawing new driver marker.." + user_id);
      var color = Colors.random();
      var marker = this.createDriverMarker(user_id, tracking_infos, color);
      marker.start();
      // marker.setZIndexOffset(5000);
      marker.addTo(operational_map);
      driver_marker = {
        user_id: user_id,
        tracking_id: last.tracking.id,
        marker: marker,
        tracking_info: last,
        color: color,
        expanded: true
      };
      drivers_markers.push(driver_marker);
    }
    return [user, last, driver_marker];
  }

  createDriverMarker(user_id, tracking_infos, color) {
    return driverMarker(tracking_infos, {
      // vehicle_img_url: map_driver_icons.ic_truck_empty,
      vehicle_img_url: map_driver_icons.ic_truck_full,
      driver_color: color,
      map: operational_map,
      // iconSize: [50, 80], // W,H
      iconSize: [38, 60], // W,H
    });
  }

  redrawDriverMarker(marker, tracking_infos, options = {}) {
    marker.marker.newDestinations(tracking_infos);
    marker.marker.redraw(tracking_infos, {
      vehicle_img_url: map_driver_icons.small,
      driver_color: marker.color,
      expanded: marker.expanded
    });
  }
  /** =====   __DRIVER ===== */

  /** =====   ORDER JOB ===== */

  /**
   * @deprecated
   * Updates existing order_jobs for driver_marker_info
   * @param {*} driver_marker
   */
  updateOrderJobsForDriver(driver_marker) {
    var order_jobs = _.filter(
      order_job_markers,
      (ojm) =>
        ojm.order_job.user && ojm.order_job.user.id == driver_marker.user_id
    );
    if (order_jobs && order_jobs.length > 0) {
      for (var oj_marker of order_jobs) {
        oj_marker.marker.redrawOrderJobOnMap(
          oj_marker.order_job,
          oj_marker.options
        );
      }
    }
  }

  addOrderJobsMapSource(mapObject, rows) {
    let source = mapObject.getSource('orderJobs');
    if(!source){
      mapObject.addSource('orderJobs', this.convertOrderJobsToMapSource(rows));
    }else{
      source.setData(this.convertOrderJobsToFeatureCollection(rows));
    }
  }

  addOrderJobClusterLayer(mapObject) {
    mapObject.addLayer({
      id: 'order-jobs-cluster',
      type: 'circle',
      source: 'orderJobs',
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    });
  }

  addOrderJobClusterCountLayer(mapObject) {
    mapObject.addLayer({
      id: 'order-jobs-cluster-count',
      type: 'symbol',
      source: 'orderJobs',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
  }

  addUnclusteredPointsLayer(mapObject) {
    var _self = this;
    mapObject.addLayer({
      id: 'order-jobs-unclustered-point',
      type: 'symbol',
      source: 'orderJobs',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': ['get', 'marker_type'],
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        // get the title name from the source's "title" property
        // 'text-field': ['get', 'title'],
        'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
        ],
        'text-offset': [0, 1.25],
        'text-anchor': 'top',
        'text-size': 12
      }
    });

    var popup = null;
    mapObject.on('click', 'order-jobs-unclustered-point', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var html = JSON.parse(e.features[0].properties.partials).popup;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
      })
          .setLngLat(coordinates)
          .setHTML(html);
      popup.addTo(mapObject);

      mapObject.getCanvas().style.cursor = 'pointer';
    });
    // Change it back to a pointer when it leaves.
    // mapObject.on('mouseleave', 'order-jobs-unclustered-point', function () {
    //   mapObject.getCanvas().style.cursor = '';
    //   if(popup){
    //     popup.remove();
    //   }
    // });

    mapObject.on('dblclick', 'order-jobs-unclustered-point', function (e) {
      _self.scrollToOrderJobAndFocus(e.features[0].properties.id);
    });

  }

  initializeSpiderLeg(spiderLeg) {
    var _self = this;
    const _map = operational_map;
    var spiderLegContainer = spiderLeg.elements.container;
    var pinElem = spiderLeg.elements.pin;
    var feature = spiderLeg.feature;
    var popup;

    spiderLegContainer.className = spiderLegContainer.className + ' custom-leg-container ';

    var svg_ref = '';
    if (feature.order_job_type == 'collect') {
      svg_ref =  '#order_job_bucket_collect';
    }

    if (feature.order_job_type == 'delivery') {
      svg_ref =  '#order_job_bucket_delivery';
    }

    if (feature.order_job_type == 'task') {
      svg_ref =  '#order_job_task';
    }


    var $spiderPinCustom = $("<div>" +
        "<svg title='clique para ver detalhes' class='map_marker_icon' height='" + 40 + "px'  width='" + 40 + "px'><use href='" + svg_ref + "'/></svg>" +
        "</div>", {class: ''});

    $(pinElem).append($spiderPinCustom);
    $spiderPinCustom.css({
        'position': 'relative',
        'width': '40px',
        'height': '40px',
        'margin-left': '-10px',
        'margin-top': '-10px',
        'font-size': '20px',
        // 'opacity': 0.85
    });

    if ($(pinElem).length) {
      $(pinElem).on('click', function(e){
            if(_self._currentPopup) {
              _self._currentPopup.remove();
            }
            popup = new mapboxgl.Popup({
              closeButton: true,
              closeOnClick: false,
              offset: MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg)
            });
            _self._currentPopup = popup; //controle global de popup em exibicação
            var htmlPopUp = feature.partials.popup;
            popup.setHTML(htmlPopUp)
                .addTo(_map)

            spiderLeg.mapboxMarker.setPopup(popup);
            e.stopPropagation();
          })
          .on('dblclick', function(e){
            _self.scrollToOrderJobAndFocus(spiderLeg.feature.id);
            e.stopPropagation();
          });
    }
  }

  scrollToOrderJobAndFocus(order_job_id) {
    let selector = "#oj-"+order_job_id
    $(selector)[0].scrollIntoView({
      behavior: "smooth", // or "auto" or "instant"
      block: "start" // or "end"
    });
    $(selector).delay(100).fadeOut().fadeIn('slow').fadeOut().fadeIn('slow');
  }

  setupSpiderifierOnMap(mapObject, mapCenter) {
    var _self = this;
    // SPIDERFY
    var spiderifier = new MapboxglSpiderifier(mapObject, {
      customPin: true,
      // animate: true,
      // animationSpeed: 100,
      onClick: function(e, spiderLeg){
       //none, utilize o initializeSpiderLeg
      },
      markerWidth: 48,
      markerHeight: 48,
      initializeLeg: this.initializeSpiderLeg.bind(this)
    }),
    SPIDERFY_FROM_ZOOM = 14,
    features = _.map(_.range(10000), function(index){
      return {
        type: 'feature',
        properties: {id: index},
        geometry: {
          type: 'Point',
          coordinates: [mapCenter[0] + (_.random(1000) * 0.001), mapCenter[1] + (_.random(1000) * 0.001)]
        }
      }
    });

    //Map related events
    mapObject.on('click', function(e){
      var features = mapObject.queryRenderedFeatures(e.point, {
        layers: ['order-jobs-cluster']
      });

      spiderifier.unspiderfy();
      if (!features.length) {
        return;
      } else if (operational_map.getZoom() < SPIDERFY_FROM_ZOOM) {
        mapObject.easeTo({center: e.lngLat, zoom: mapObject.getZoom() + 2});
      } else {
        mapObject.getSource('orderJobs').getClusterLeaves(
            features[0].properties.cluster_id,
            100,
            0,
            function(err, leafFeatures){
              if (err) {
                return console.error('error while getting leaves of a cluster', err);
              }
              var markers = _.map(leafFeatures, function(leafFeature){
                return leafFeature.properties;
              });
              spiderifier.spiderfy(features[0].geometry.coordinates, markers);

              $('.custom-leg-container').each(function(i, dom){
                //workaround para remover blur devido ao rotate no spider leg container
                dom.style.transform = dom.style.transform.replace('translate(-50%, -50%)', '')
              });

            }
        );
      }
    });
    mapObject.on('mousemove', function(e) {
      var features = mapObject.queryRenderedFeatures(e.point, {
        layers: ['order-jobs-cluster']
      });
      mapObject.getCanvas().style.cursor = (features.length) ? 'pointer' : '';
    });
    mapObject.on('zoomstart', function(){
      spiderifier.unspiderfy();
    });

  }

  /**
   * Cria a source data de order jobs para o mapa
   * @param order_job_table_rows
   * @returns {{cluster: boolean, data: {features: [], type: string}, type: string, clusterMaxZoom: number, clusterRadius: number}}
   */
  convertOrderJobsToMapSource(order_job_table_rows) {
    return {
      type: 'geojson',
      data: this.convertOrderJobsToFeatureCollection(order_job_table_rows),
      cluster: true,
      clusterMaxZoom: 25,
      clusterRadius: 50
    };
  }

  /**
   * Converte as linhas da tabele de controle para (extraindo o data geojson) e retornando um GEOJSON
   * para ser usado na source do mapa
   * @param order_job_table_rows
   * @returns {{features: [], type: string}}
   */
  convertOrderJobsToFeatureCollection(order_job_table_rows) {
    var features = [];
    for (var row of order_job_table_rows) {
      features.push(JSON.parse(row.dataset.geojson));
    }

    return {
      "type": "FeatureCollection",
      "features": features
    };
  }
  /** =====   __ORDER JOB ===== */

  showProgress() {
    $("#progress-bar").removeClass("invisible");
    $("#progress-bar").addClass("visible");
    // $("#progress-bar").removeClass("d-none");
    clog('show progress');

  }

  hideProgress() {
    $("#progress-bar").removeClass("visible");
    $("#progress-bar").addClass("invisible");
    // $("#progress-bar").addClass("d-none");
    clog('hide progress');

  }

  afterPullOrderJobs() {
    clog('after pull oj')
    this.hideProgress();
    this.initializeOrderJobFilterSelect2();
  }


  afterPullDrivers() {
    this.hideProgress();
  }

  /**
   * Maps one option from map-full-screen-form
   * to global-filter-form.
   * This aprroach avoid stimulus reflex/cable ready elements checked conflicts
   * @param event
   */
  toggleFilterCheckbox(event) {
    //trigger click to corresponding global filter element
    document.getElementById(event.params['elementid']).click();
  }

  initializeOrderJobFilterSelect2() {
    $("#order_job_district_name_list").select2({
      language: "pt-BR",
      theme: "material",
      dropdownParent: "#oj_tags_container",
      placeholder: "Selecione bairros para filtrar atendimentos..",
      multiple: true,
      tokenSeparators: [','],
      ajax: {
        url: this.metadataTarget.dataset["urlDistrictList"]+".json",
        dataType: 'json',
        delay: 400,
        data: function (params) {
          return {
            q: params.term || undefined,
            page: params.page || 1
          };
        },
        processResults: function (data) {
          return {
            results: data.map(function(item) {
              return {
                id: item[0],
                text: item[1],
              }
            })
          }
        }
      }
    });

    $('#order_job_district_name_list').on('select2:select', function (e) {
      $('#refresh_order_jobs_submit').click();
    });

    $('#order_job_district_name_list').on('select2:unselect', function (e) {
      $('#refresh_order_jobs_submit').click();
    });

    $("#order_job_tag_list").select2({
      language: "pt-BR",
      theme: "material",
      dropdownParent: "#oj_tags_container",
      placeholder: "Selecione tags para filtrar atendimentos..",
      multiple: true,
      tokenSeparators: [','],
      ajax: {
        url: this.metadataTarget.dataset["urlTagList"]+".json",
        dataType: 'json',
        delay: 400,
        data: function (params) {
          return {
            q: params.term || undefined,
            page: params.page || 1
          };
        },
        processResults: function (data) {
          return {
            results: data.map(function(item) {
              return {
                id: item[0],
                text: item[1],
              }
            })
          }
        }
      }
    });

    $('#order_job_tag_list').on('select2:select', function (e) {
      $('#refresh_order_jobs_submit').click();
    });

    $('#order_job_tag_list').on('select2:unselect', function (e) {
      $('#refresh_order_jobs_submit').click();
    });

  }

  isPlacedChecked() {
    return document.getElementById("checkbox-placed").checked;
  }

  isAssignedChecked() {
    return document.getElementById("checkbox-assigned").checked;
  }

  isStartedChecked() {
    return document.getElementById("checkbox-started").checked;
  }

  isArrivedChecked() {
    return document.getElementById("checkbox-checked").checked;
  }

  isDoneChecked() {
    return document.getElementById("checkbox-done").checked;
  }

  isFailedChecked() {
    return document.getElementById("checkbox-failed").checked;
  }

  isCancelledChecked() {
    return document.getElementById("checkbox-cancelled").checked;
  }

  selectedOrderJobStatuses() {
    return _.map($("#oj_status_container").find(":checked"), function (v) {
      return v.value;
    });
  }
}
