import ApplicationController from "../application_controller";
import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

let debug = false

function clog(it) {
  if (debug) {
    console.log(it);
  }
}

export default class extends ApplicationController {

  static values = {
    partnerId: String,
    performAtDay: String,
    driverId: String
  }

  connect() {
    this.element[this.identifier] = this;
    this.subscriptions = [];
    this.partnerId = this.partnerIdValue;
    this.setup();

    this.hideOthersOrderJobs();
  }

  disconnect() {
    this.unregisterChannels();
  }


  orderJobsDriverFullscreen(event) {
    event.preventDefault();
    if ($("#card_driver_order_jobs").hasClass("oj-driver-fullscreen")) {
      $("#card_driver_order_jobs").removeClass("oj-driver-fullscreen");
      $("#card_driver_order_jobs").removeClass("col-md-12");
      $("#card_driver_order_jobs").addClass("col-md-6");

      $("#btn_hide_oj_others").removeClass("d-none");
      $("#btn_show_oj_others").addClass("d-none");

      $("#card_others_order_jobs").removeClass("d-none");
    } else {
      $("#card_driver_order_jobs").removeClass("col-md-6");
      $("#card_driver_order_jobs").addClass("col-md-12");
      $("#card_driver_order_jobs").addClass("oj-driver-fullscreen");

      $("#btn_hide_oj_others").addClass("d-none");
      $("#btn_show_oj_others").removeClass("d-none");

      $("#card_others_order_jobs").addClass("d-none");
    }
  }

  hideOthersOrderJobs() {
    if ($("#card_driver_order_jobs").hasClass("oj-driver-fullscreen")) {
      $("#card_driver_order_jobs").removeClass("col-md-6");
      $("#card_driver_order_jobs").addClass("col-md-12");
      $("#card_driver_order_jobs").addClass("oj-driver-fullscreen");

      $("#btn_hide_oj_others").addClass("d-none");
      $("#card_others_order_jobs").addClass("d-none");
      $("#list_others_order_jobs").find("tr").remove();

      let $selectedElement = $('#list_driver_order_jobs')
      $selectedElement.find('.icon-sortable-vertical').removeClass('handle-sortable-vertical')
      $selectedElement.find('.icon-sortable-vertical').css('cursor', 'not-allowed');
    }
  }

  hideSectionMap() {
    $("#demand-plannings-map").addClass("d-none");
    $("#btn_show_section_map").removeClass("d-none");
    $("#btn_hide_section_map").addClass("d-none");
  }

  showSectionMap() {
    $("#demand-plannings-map").removeClass("d-none");
    $("#btn_show_section_map").addClass("d-none");
    $("#btn_hide_section_map").removeClass("d-none");
  }

  setup() {
    var _this = this
    clog('Controller SETUP');
    this.registerChannels();

    $(document).on("driver_" + this.driverIdValue + ":date_" + this.performAtDayValue + ":daily_demand_changed", function (event) {
      _this.handleDemandPlannings(event);
    });

    $(document).on("partner_" + _this.partnerId + ":order_job_changed", function (event) {
      _this.handleOrderJobChanged(event)
    })
  }

  registerChannels() {
    clog("creating subscription for PartnerChannel " + this.partnerId);
    this.subscriptions.push(
        //partner
        consumer.subscriptions.create(
            { channel: "PartnerChannel", partner_id: this.partnerId },
            {
              received(data) {
                clog("Received on channel ");
                clog(data);
                if (data.cableReady) CableReady.perform(data.operations);
              },
            }
        )
    );
  }

  unregisterChannels() {
    for (var sub in this.subscriptions) {
      clog("removing subscriptions ");
      clog(sub);
      consumer.subscriptions.remove(sub);
    }
  }

  handleDemandPlannings(event) {
    $('#card_driver_order_jobs').find('.refresh-changed-checkbox-enable').remove();
    $('#card_driver_order_jobs').find('.refresh-changed-checkbox-disable').removeClass('d-none');

    $('#card_driver_order_jobs').find('.dd-menu-container').remove();
    $('#card_driver_order_jobs').find('.refresh-update').removeClass('d-none');

    let $selectedElement = $('#list_driver_order_jobs')

    $selectedElement.find("a:not([data-refresh-link])").removeAttr('href');
    $selectedElement.find("a:not([data-refresh-link])").removeAttr('data-method');
    $selectedElement.find('.dd-menu-container').remove();
    $selectedElement.addClass("bg-info");
    $selectedElement.find('.refresh-changed-checkbox-enable').remove();
    $selectedElement.find('.refresh-changed-checkbox-disable').removeClass('d-none');

    $selectedElement.find('.icon-sortable-vertical').removeClass('handle-sortable-vertical')
    $selectedElement.find('.icon-sortable-vertical').css('cursor', 'not-allowed');
  }

  handleOrderJobChanged(event) {
    if (event && event.detail && event.detail.orderJobId) {
      let orderJobId = event.detail.orderJobId;
      let statusChanged = event.detail.statusChanged;
      let selector = "#oj-"+orderJobId;

      let $selectedElement = $('#list_others_order_jobs').find(selector);

      if ($selectedElement.length > 0) {
        $selectedElement.find("a:not([data-refresh-link])").removeAttr('href');
        $selectedElement.find("a:not([data-refresh-link])").removeAttr('data-method');
        $selectedElement.find('.dropdown').remove();
        $selectedElement.find('.refresh-changed-checkbox-enable').remove();
        $selectedElement.find('.refresh-changed-checkbox-disable').removeClass('d-none');

        $selectedElement.find('.icon-sortable-horizontal').removeClass('handle-sortable-horizontal')
        $selectedElement.find('.icon-sortable-horizontal').css('cursor', 'not-allowed');

        if(!statusChanged){
          if( $selectedElement.find('a.refresh-update[data-refresh-link]') ) {
            $selectedElement.find('a.refresh-update[data-refresh-link]').removeClass('d-none');
            $selectedElement.addClass("bg-info");
          }
        } else {
          $selectedElement.find('a.refresh-update[data-refresh-link]').remove();
          console.log('remove-refresh');
          $selectedElement.find('a.refresh-changed-status[data-refresh-link]').removeClass('d-none');
          $selectedElement.addClass("bg-warning");
        }
      }
    }
  }
}
